@import "../../../Styles/mixins.scss";
.wrapper {
  position: relative;
  overflow: hidden;
  padding: 160px 0 100px;
  @include mobile() {
    padding: 110px 25px 25px;
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 120px;
    @include mobile() {
        flex-direction: column;
        gap: 50px;
    }
}
.content {
  flex: 1;
  h1 {
    color: #000;

    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 65px; /* 162.5% */
    letter-spacing: -0.4px;
    margin-bottom: 10px;
    @include mobile() {
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.32px;
      text-align: center;
    }
  }
  p{
    color: #000;
    font-family:var(--secondary-font);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    letter-spacing: -0.2px;
    margin-bottom: 20px;
    @include mobile() {
      text-align: center;
    }
  }
  .ctaContainer {
    display: flex;
    align-items: center;
    gap: 20px;

  }
  .countList {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    color: #daa505;
    @include mobile() {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 20px;
      column-gap: 0px;
    }
    .countContainer {
      padding: 0 20px;
      border-right: 1px solid #000;
      &:last-child {
        border-right: none;
      }
      @include mobile() {
        &:nth-child(2) {
          border-right: none;
        }
      }
      .number {
        text-align: center;
        font-family: var(--secondary-font);
        color: #DAA505;
        font-size: 32.261px;
        font-style: normal;
        font-weight: 500;
        line-height: 41.231px; /* 127.805% */
        letter-spacing: -0.323px;
        margin-bottom: 5px;
      }
      .caption {
        color: #000;
        text-align: center;
        font-family: var(--primary-font);
        font-size: 16.278px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.163px;
        white-space: nowrap;
      }
    }
    .verticalDivider {
      width: 1px;
      height: 78px;
      background: #000;
    }
  }
}
.rightImage {
  flex: 1;
  img{
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
