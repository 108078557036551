@import '../../../Styles/mixins.scss';
.wrapper {
  padding: 40px 0 0;
  @include mobile() {
    padding: 25px 0px 0;
  }
  h2 {
    color: #000;
    text-align: center;
    font-family: var(--primary-font);
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 65px; /* 162.5% */
    letter-spacing: -0.4px;
    margin-bottom: 50px;
    @include mobile() {
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.32px;
      margin-bottom: 30px;
      padding: 0 25px;
    }
  }
  ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @include mobile() {
      grid-template-columns: 1fr 1fr;
    }
    .link {
      text-align: center;
      padding: 50px;
      &:nth-child(odd) {
        background: #fff;
      }
      @include mobile() {
        padding: 20px;

      }
      img {
        width: 50px;
        height: 50px;
        margin-bottom: 15px;
      }
      h3 {
        color: #000;
        text-align: center;
        font-family: var(--primary-font);
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 106%; /* 25.44px */
        letter-spacing: -0.3px;
        margin-bottom: 25px;
        @include mobile() {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 106%; /* 16.96px */
          letter-spacing: -0.3px;
            margin-bottom: 15px;
        }
      }
      p {
        max-width: 320px;
        margin: 0 auto;
        color: #000;
        text-align: center;
        font-family: var(--secondary-font);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 150% */
        letter-spacing: -0.2px;
        @include mobile() {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          letter-spacing: -0.16px;
        }
      }
    }
  }
}
