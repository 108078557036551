@import '../../../Styles/mixins.scss';
.wrapper {
  padding: 80px 0;
  @include mobile() {
    padding: 40px 25px;
  }
  h3 {
    color: #000;
    text-align: center;
    font-family: var(--primary-font);
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 65px; /* 135.417% */
    letter-spacing: -0.48px;
    margin-bottom: 50px;
    @include mobile() {
      color: #000;
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.32px;
      margin-bottom: 30px;
    }
  }
  ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 60px;
    row-gap: 65px;
    justify-content: center;
    @include mobile() {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 20px;
      row-gap: 30px;
      justify-content: space-between;
    }
    &.ebookGrid {
      grid-template-columns: repeat(4, 1fr);
      justify-content: center;
      @include mobile() {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
        row-gap: 20px;
        justify-content: center;
      }
    }
    li {
      text-align: center;
      img {
        width: 70px;
        height: 70px;
        object-fit: contain;
        margin-bottom: 15px;
      }
      h4 {
        color: #000;
        text-align: center;
        font-family: var(--primary-font);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.18px;
      }
    }
  }
}
