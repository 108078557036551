.wrapper{
  border-radius: 33px;
  border: none;
  background: #FF3E4F;
  padding: 10px 20px;

  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}