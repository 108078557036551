@import '../../../Styles/mixins.scss';

.wrapper {
  position: relative;
  overflow: hidden;
  padding: 160px 0 100px;
  background-image: linear-gradient(to top, #f4f6ff 300px, white 10%);
  @include mobile() {
    padding: 150px 25px 25px;
  }

  h1 {
    color: #000;
    text-align: center;
    font-family: var(--primary-font);
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 64.742px; /* 134.878% */
    letter-spacing: -0.48px;
    max-width: 800px;
    margin: 0 auto 20px;
    @include mobile() {
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.32px;
      margin: 0 auto 15px;
    }
  }
  p {
    max-width: 800px;
    margin: 0 auto 25px;
    color: #000;
    text-align: center;
    font-family: var(--secondary-font);
    font-size: 19.92px;
    font-style: normal;
    font-weight: 400;
    line-height: 29.881px; /* 150% */
    letter-spacing: -0.199px;
    @include mobile() {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 150% */
      letter-spacing: -0.2px;
    }
  }
}
.ctaContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  flex-wrap: wrap;
}
.countWrapper {
  margin-top: 25px;
  .countLabel {
    color: #000;
    text-align: center;
    font-family: var(--primary-font);
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.2px;
  }
  .countList {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;
    margin-top: 20px;
    color: #7689e1;
    @include mobile() {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 20px;

    }
    .countContainer {
      padding: 0 20px;
      border-right: 1px solid #000;
      &:last-child {
        border-right: none;
      }
      @include mobile() {
        &:nth-child(2) {
          border-right: none;
        }
        &:last-child {
          grid-column: span 2;
        }
      }
      .number {
        text-align: center;
        font-family: var(--secondary-font);
        font-size: 52.165px;
        font-style: normal;
        font-weight: 400;
        line-height: 69.347px; /* 132.937% */
        letter-spacing: -0.522px;
        margin-bottom: 5px;
        @include mobile() {
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.4px;
        }
      }
      .caption {
        color: #000;
        text-align: center;
        font-family: var(--primary-font);
        font-size: 16.278px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.163px;
      }
    }
    .verticalDivider {
      width: 0.9px;
      height: 78px;
      background: #000;
      @include mobile() {
        display: none;
      }
    }
  }
}

.bookContainer {
  position: relative;
  padding-top: 50px;
  display: flex;
  justify-content: center;
  .stableBookList {
    display: flex;
    justify-content: center;
    gap: 100px;
    position: relative;
    @include mobile() {
      gap: 70px;
    }
    img {
      width: 216.386px;
      height: 290.498px;
      object-fit: contain;
      @include mobile() {
        width: 100px;
        height: 150px;
      }
    }
    .leftImagesGroup {
      position: absolute;
      left: -110%;
      display: flex;
      justify-content: center;
      gap: 60px;
      top: -100px;
    }
    .rightImagesGroup {
      position: absolute;
      right: -110%;
      display: flex;
      justify-content: center;
      gap: 60px;
      top: -100px;
    }
  }
}
