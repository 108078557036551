@import '../../Styles/mixins.scss';

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @include mobile() {
    grid-template-columns: 1fr;
  }
}
.area {
  border: 0.5px solid #ced6ff;
  &:nth-child(1) {
    border-left: 0;
    @include mobile() {
      grid-column: span 2;
    }
  }
  &:nth-child(2) {
    border-left: 0;
    @include mobile() {
      grid-column: span 2;
    }
  }
  &:nth-child(3) {
    border-right: 0;
    border-left: 0;
    grid-column: span 2;
  }
  &:nth-child(4) {
    border-top: 0;
    border-left: 0;
  }
  &:nth-child(5) {
    border-top: 0;
    border-left: 0;
  }
  &:nth-child(6) {
    border: 0;
    grid-column: span 2;
  }
}
.address {
  padding: 30px 40px;
  @include mobile() {
    padding: 20px 25px;
  }
  .logName {
    color: #6a6a6a;
    font-family: var(--primary-font);
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.2px;
    margin-bottom: 22px;
    @include mobile() {
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: -0.2px;
    }
  }
  .addr {
    color: #6a6a6a;
    font-family: var(--secondary-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.16px;
    padding-bottom: 15px;

  }
  .services {
    padding: 0;
    margin-top: 15px;
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}

.services {
  padding: 30px 40px;
  @include mobile() {
    padding: 20px 25px;
  }
  .title {
    color: #6a6a6a;
    font-family: var(--primary-font);
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.2px;
  }
  ul {
    margin-top: 20px;
    list-style: none;
    display: grid;
    column-gap: 24px;
    row-gap: 15px;
    grid-template-columns: 1fr;
    li {
      a {
        padding-top: 0;
        color: #6a6a6a;
        font-family: var(--secondary-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.14px;
      }
    }
  }
  a {
    padding-top: 28px;
    display: block;
    color: #913c00;
    font-family: var(--primary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
  }
}

.genre {
  padding: 30px 40px;
  @include mobile() {
    padding: 20px 25px;
  }
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 30px;
    @include mobile() {
      grid-template-columns: 1fr 1fr;
    }
  }
}
.rating {
  padding: 30px 42px;
  color: #6a6a6a;
  font-family: var(--secondary-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;
  display: flex;
  gap: 10px;
  span {
    color: #ced6ff;
  }
  @include mobile() {
    padding: 20px 25px;
    justify-content: center;
  }
}
.social {
  padding: 30px 42px;
  color: #000;
  font-family: var(--secondary-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  @include mobile() {
    padding: 20px 25px;
    justify-content: center;
  }
}
.copyRight {
  padding: 30px 42px;
  color: #000;
  font-family: var(--secondary-font);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
  display: flex;
  justify-content: center;
  @include mobile() {
    padding: 20px 25px;
  }
  img {
    width: 75px;
    height: auto;
    object-fit: contain;
  }
}
