@import '../../../Styles/mixins.scss';
.wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(to top, #f4f6ff 500px, white 10%);
  @include mobile() {
    padding: 60px 25px;
  }
  h5 {
    color: #000;
    text-align: center;
    font-family: var(--primary-font);
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 134.667%; /* 56.56px */
    letter-spacing: -0.42px;
    @include mobile() {
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 134.667%; /* 43.093px */
      letter-spacing: -0.32px;
    }
  }
}
.compare {
  padding: 10px 15px;
  background: #6d80d6;
  color: #fff;
  text-align: center;
  font-family: var(--secondary-font);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 134.667%; /* 26.933px */
  letter-spacing: -0.2px;
  border: none;
  margin-top: 40px;
  @include mobile() {
    display: none;
  }
}
.packageCardList {
  display: flex;
  gap: 50px;
  margin-top: 42px;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  @include mobile() {
    flex-direction: column;
  }
  .packageCard {
    box-shadow: 3.758px 3.758px 9.394px 0px rgba(0, 0, 0, 0.15);
    border-radius: 28.182px;
    background: #fff;
    max-width: 300px;
    position: relative;
    .badge{
      width: 55px;
      height: 58.022px;
      position: absolute;
      top: -10px;
      right: 30px;
      z-index: 2;
    }
    .topPart {
      padding: 28.18px 37px;
      border-radius: 28.182px 28.182px 0px 0px;
      background: #ffeeef;
      text-align: center;
      position: relative;
      .packageImg {
        margin-bottom: 15px;
      }
      .title {
        color: #000;
        text-align: center;
        font-family: var(--primary-font);
        font-size: 22.545px;
        font-style: normal;
        font-weight: 400;
        line-height: 134.667%; /* 30.361px */
        letter-spacing: -0.225px;
        margin-bottom: 9px;
      }
      .price {
        color: #464646;
        text-align: center;
        font-family: var(--primary-font);
        font-size: 45.091px;
        font-style: normal;
        font-weight: 400;
        line-height: 134.667%; /* 60.722px */
        letter-spacing: -0.451px;
        sup {
          font-size: 22px;
        }
      }
      .subTitle {
        color: #000;
        text-align: center;
        font-family: var(--secondary-font);
        font-size: 18.788px;
        font-style: normal;
        font-weight: 400;
        line-height: 134.667%; /* 25.301px */
        letter-spacing: -0.188px;
      }
      .cta {
        border-radius: 9.394px;
        background: #fff;
        box-shadow: 3.758px 3.758px 3.758px 0px rgba(0, 0, 0, 0.1);
        margin-top: 15px;
        padding: 10px;
        color: #ff7893;
        text-align: center;
        font-family: var(--secondary-font);
        font-size: 22.545px;
        font-style: normal;
        font-weight: 400;
        line-height: 134.667%; /* 30.361px */
        letter-spacing: -0.225px;
      }
      .dividerContainer {
        position: absolute;
        bottom: -7px;
        left: 10px;
        right: 10px;
      }
      .divider {
        margin-bottom: 0;
        width: 95%;
      }
    }
    .bottomPart {
      padding: 50px 20px;
      .benefitList {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 20px;
        li {
          display: flex;
          align-items: center;
          gap: 10px;
          color: #000;
          font-family: var(--secondary-font);
          font-size: 16.909px;
          font-style: normal;
          font-weight: 400;
          line-height: 134.667%; /* 22.771px */
          letter-spacing: -0.169px;
        }
      }
    }

    &.two {
      z-index: 10;
      .topPart {
        background: #e4e9ff;
        .price {
          color: #6d80d6;
          sup {
            color: #000;
          }
        }
        .cta {
          color: #6d80d6;
        }
      }
    }
    &.three {
      .topPart {
        background: #eefff1;
        .price {
          color: #000;
          sup {
            color: #000;
          }
        }
        .cta {
          color: #309033;
        }
      }
    }
  }
}

.sale {
  text-align: center;
  color: #000;
  font-family: var(--primary-font);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 134.667%;
  letter-spacing: -0.2px;
  margin-top: 21px;
  @include mobile() {
    font-size: 16px;
    padding: 0 25px;
  }
  a {
    color: #6d80d6;
  }
}
