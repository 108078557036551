@import '../../../Styles/mixins.scss';
.wrapper {
  padding: 40px 0;
  @include mobile() {
    padding: 20px 25px;
  }
  h4 {
    color: #000;
    text-align: center;

    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 65px; /* 135.417% */
    letter-spacing: -0.48px;
    margin-bottom: 40px;
    @include mobile() {
      color: #000;
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.32px;

    }
  }
}
.cardList {
  list-style: none;
  display: flex;
  gap: 37.8px;
  @include mobile() {
   flex-direction: column;
  }
  .card {
    border-radius: 5.4px 5.4px 0 0;
    width: 100%;
    overflow: hidden;
    .top {
      background: #efa8ac;
      padding: 0 54px;
      overflow: hidden;

      .inside {
        border-radius: 5.4px 5.4px 0 0;
        background: #fff;
        padding: 10px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        transform: translateY(30px);
        .title {
          color: #efa8ac;
          text-align: left;
          font-family: var(--secondary-font);
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.066px;
          opacity: 0.9;
        }
        .letter {
          padding: 17px 17px 0;
          .text {
            color: #efa8ac;
            font-family: var(--secondary-font);
            font-size: 15.12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.066px;
            margin-bottom: 10px;
          }
          .content {
            color: #000;
            font-family: var(--secondary-font);
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.066px;
          }
        }
      }
    }
    .bottom {
      padding: 30px 27px 25px;
      background: #fff9f9;
      .title {
        color: #000;
        font-family: var(--secondary-font);
        font-size: 15.12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.066px;
        margin-bottom: 5px;
      }
      .subtitle {
        color: #efa8ac;
        font-family: var(--secondary-font);
        font-size: 21.6px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.066px;
        margin-bottom: 10px;
      }
      .desc {
        color: #000;
        font-family: var(--secondary-font);
        font-size: 15.12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.066px;
        margin-bottom: 27px;
      }
      .cta {
        color: #efa8ac;
        font-family: var(--secondary-font);
        font-size: 17.28px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.066px;
        border-radius: 45.36px;
        border: 1px solid #efa8ac;
        padding: 10px;
        text-align: center;
      }
    }
  }
}
