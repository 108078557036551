@import '../../../Styles/mixins.scss';
.wrapper {
  padding: 40px 0;
  @include mobile() {
    padding: 40px 25px;
  }

  h5 {
    color: #0f0f10;
    font-family: var(--secondary-font);
    font-size: 46.228px;
    font-style: normal;
    font-weight: 500;
    line-height: 121%; /* 55.936px */
    letter-spacing: 1.387px;
    text-align: center;
  }
  .subTitle {
    color: #9e9e9e;
    font-family: var(--secondary-font);
    font-size: 23.114px;
    font-style: normal;
    font-weight: 400;
    line-height: 124.5%; /* 28.777px */
    letter-spacing: -0.462px;
    text-align: center;
  }

  .faqsCont {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 30px;
    row-gap: 20px;
    margin-top: 30px;
    @include mobile() {
      grid-template-columns: 1fr;
      column-gap: 0;
      row-gap: 20px;
      margin-top: 20px;
    }
    .items {
      flex: 1;
      padding: 20px;
      align-items: center;
      cursor: pointer;
      align-self: flex-start;
      border-radius: 15px;
      border: 1px solid #9aabff;

      background: #fff;

      .header {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        align-items: center;
        p {
          color: #2f302f;
          font-family: var(--secondary-font);
          font-size: 19.262px;
          font-style: normal;
          font-weight: 500;
          line-height: 124.5%; /* 23.981px */
          letter-spacing: -0.385px;
          @include mobile() {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 124.5%; /* 19.92px */
            letter-spacing: -0.32px;
          }
        }

        .arrow {
          width: 21px;
          height: 21px;
        }
      }

      .answer {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition:
          max-height 0.3s ease,
          opacity 0.3s ease,
          padding-top 0.3s ease;
        color: #2f302f;
        font-family: var(--secondary-font);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 124.5%; /* 23.981px */
        letter-spacing: -0.385px;
        @include mobile() {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 124.5%; /* 19.92px */
          letter-spacing: -0.32px;
        }
      }

      &:nth-child(n).active .answer {
        padding-top: 18px;
        max-height: 100px; // Adjust this value according to your content
        opacity: 1;
      }
    }
  }
}
