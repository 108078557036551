@import '../../../Styles/mixins.scss';
.wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @include mobile() {
    padding: 60px 25px;
    min-height: unset;
  }
  h5 {
    color: #000;
    text-align: center;
    font-family: var(--primary-font);
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 134.667%; /* 53.867px */
    letter-spacing: -0.4px;
    @include mobile() {
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 134.667%; /* 43.093px */
      letter-spacing: -0.32px;
    }
  }
}
.cardList {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 17px;
  justify-content: center;
  @include mobile() {
    display: none;
  }
}
.card {
  padding: 23px 72px 23px 25px;
  border-radius: 15.508px;
  box-shadow: 2.142px 2.142px 5.354px 0px rgba(0, 0, 0, 0.1);
  max-width: 270px;
  @include mobile() {
    padding: 20px 40px;
    text-align: center;
    max-width: unset;
  }
  img {
    width: 39px;
    height: 36px;
    margin-bottom: 16px;
    @include mobile() {
      width: 47.251px;
      height: 47.251px;
      margin: 0 auto 16px;
    }
  }
  .title {
    color: #000;
    font-family: var(--primary-font);
    font-size: 17.133px;
    font-style: normal;
    font-weight: 300;
    line-height: 134.667%; /* 23.072px */
    letter-spacing: -0.171px;
    margin-bottom: 10px;
    @include mobile() {
      font-size: 20.881px;
      font-style: normal;
      font-weight: 400;
      line-height: 134.667%; /* 28.119px */
      letter-spacing: -0.209px;
    }
  }
  .subtitle {
    color: #000;
    font-family: var(--secondary-font);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; /* 166.667% */
    letter-spacing: -0.093px;
    margin-bottom: 10px;
    @include mobile() {
      font-size: 11.34px;
      font-style: normal;
      font-weight: 400;
      line-height: 18.9px; /* 166.667% */
      letter-spacing: -0.113px;
    }
  }
  .cta {
    font-size: 8.271px !important;
    @include mobile() {
      font-size: 10.08px !important;
    }
  }
}

.sliderContainer {
  width: 300px;
  display: none;
  @include mobile() {
    display: block;
    margin: 30px auto 0;
  }
  .slick-dots{
    background: red;
  }
}

