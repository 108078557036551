@import './mixins.scss';
.wrapper {
}
.hero {
  background: #f4f6ff;
  padding: 160px 0 30px 0;
  @include mobile() {
    padding: 150px 25px 30px 25px;
  }
  .breadcrumb {
    color: #606060;
    font-family: var(--secondary-font);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    gap: 7px;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px;
  }
  h1 {
    color: #7689e1;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @include mobile() {
      font-size: 40px;
    }
  }
}
.content {
  padding: 60px 0;
  color: #747474;
  font-family: var(--secondary-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 121%;
  letter-spacing: 0.48px;
  @include mobile() {
     padding: 40px 25px;
  }
  p{
    margin-bottom: 20px;
  }
  h1,h2,h3,h4,h5,h6 {
    color: #7689e1;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.6px;
    margin-bottom: 10px;
  }
  h3 {
    color: #7689e1;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 121%; /* 24.2px */
    letter-spacing: 0.6px;
    font-family: var(--primary-font);
    margin-bottom: 5px;
  }
  ol,
  ul {
    padding-left: 20px;
  }
}
  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50px;
    padding: 8px;

    aspect-ratio: 1;
    border-radius: 50%;
    background: #7689e1;
    --_m:
            conic-gradient(#0000 10%,#000),
            linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {to{transform: rotate(1turn)}}