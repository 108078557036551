@import '../../../Styles/mixins.scss';
.wrapper {
  padding: 120px 0;
  @include mobile(){
    padding: 60px 25px;
  }
  h5 {
    color: #080808;
    text-align: center;
    font-family: var(--primary-font);
    font-size: 39.772px;
    font-style: normal;
    font-weight: 400;
    line-height: 116.5%; /* 46.334px */
    letter-spacing: -0.795px;
    margin-bottom: 28px;
    @include mobile(){
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 116.5%; /* 37.28px */
      letter-spacing: -0.64px;
    }
  }
}

.blogList {
  display: flex;
  gap: 34px;
  @include mobile(){
    flex-direction: column;
    gap: 20px;
  }
  .blogCard {
    background: #f4f6ff;
    padding: 27px 15px;
    .cat {
      border-radius: 20px;
      background: #7689e1;
      display: inline-flex;
      padding: 5px 10px;
      color: #fff;
      text-align: center;
      font-family: var(--secondary-font);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.12px;
      margin-bottom: 15px;
    }
    .title {
      color: #7689e1;
      font-family: var(--secondary-font);
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.32px;
      margin-bottom: 15px;
    }
    .subTitle {
      color: #666;
      font-family: var(--secondary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;
    }
    .bottomPanel {
      display: flex;
      margin-top: 18px;
      gap: 5px;
      align-items: center;
      color: #000;
      font-family: var(--secondary-font);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.18px;
      .divider {
        background: #d9d9d9;
        width: 5px;
        height: 5px;
      }
    }
  }
}
