.wrapper {
  border-radius: 33px;
  border: 1px solid #ff3e4f;
  background: unset;
  padding: 10px 20px;

  color: #ff3e4f;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}
