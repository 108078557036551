@import "../../../Styles/mixins.scss";
.wrapper {
  background: #fff2cc;
  padding: 40px 0;
  @include mobile() {
    padding: 40px 25px;
  }
  h4 {
    color: #000;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 65px; /* 162.5% */
    letter-spacing: -0.4px;
    @include mobile() {
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 43.093px;
      letter-spacing: -0.32px;
    }
  }
}
.list {
  padding: 30px 0;
  display: flex;
  gap: 30px;
  justify-content: center;
  @include mobile() {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  .card {
    border-radius: 10.189px;
    background: #fff;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
    padding: 30px;
    max-width: 220px;
    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
      margin-bottom: 10px;
    }
    h5 {
      color: #000;
      font-family: var(--secondary-font);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.204px;
      margin-bottom: 15px;
    }
    p {
      color: #000;
      font-family: var(--secondary-font);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.204px;
    }
  }
}
