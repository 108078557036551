@import "./mixins.scss";
.wrapper {
}
.hero {
  padding: 160px 0 100px;
  background: #f4f6ff;
  h1{
    color: #7689E1;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 630px;
    margin: 0 auto;
  }
  p{
    color: #747474;
    text-align: center;
    font-family: var(--secondary-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 121%; /* 19.36px */
    letter-spacing: 0.48px;
    margin-top: 15px;
  }
}
.breadcrumb {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #606060;
  font-family: var(--secondary-font);
  font-size: 17.778px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
}
.icon{
  width: 60px;
  height: 60px;
  display: block;
  margin: 0 auto 18px;
}
.content{
  padding:50px 0px;
  h2{
    color: #7689E1;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 25px;
  }
  h3{
    color: #7689E1;
    color: #6a6a6a;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 18px;
  }
  ul{
    box-sizing: border-box;
    width: 1024px;
    max-width: 100%;
    margin: 25px auto;
    padding: 0 15px;
    padding-left: 40px;

    li {
      &:not(:last-child){
        margin-bottom: 10px;
      }
    }
  }
  p{
    color: #747474;
    text-align: center;
    font-family: var(--secondary-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 121%; /* 19.36px */
    letter-spacing: 0.48px;
    margin-bottom: 15px;
  }
}
