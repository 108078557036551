@font-face {
  font-family: 'Satoshi';
  src: url('../../public/fonts/Satoshi-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../../public/fonts/Satoshi-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../../public/fonts/Satoshi-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../../public/fonts/Satoshi-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../../public/fonts/Satoshi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Ogg Text Font */
/*@font-face {*/
/*    font-family: 'Satoshi';*/
/*    src: url('../../public/fonts/O') format('opentype');*/
/*    font-weight: 900;*/
/*    font-style: normal;*/
/*}*/
@font-face {
  font-family: 'OggText';
  src: url('../../public/fonts/OggText-Extrabold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'OggText';
  src: url('../../public/fonts/OggText-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'OggText';
  src: url('../../public/fonts/OggText-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'OggText';
  src: url('../../public/fonts/OggText-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'OggText';
  src: url('../../public/fonts/OggText-Book.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

:root {
  --primary-font: 'OggText';
  --secondary-font: 'Satoshi';
}

* {
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}
body {
  font-family: 'OggText', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
  color: inherit;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

.home-process-slider {
  .slick-dots {
    bottom: -30px;
    li {
      margin: 0;

      button:before {
        font-size: 10px;
        color: #FFF5F4;
        opacity: 1;
      }

      &.slick-active button:before {
        color: #FF3E4F;
      }
    }
  }
}

/*slider*/
