@import '../../../Styles/mixins.scss';
.wrapper {
  position: relative;
  overflow: visible;
  padding: 150px 0;
  // height: 3350px;
  @include mobile() {
    flex-direction: column;
    justify-content: center;
  }
  h1 {
    color: #000;
    text-align: center;
    font-size: 52px;
    font-style: normal;
    font-weight: 300;
    line-height: 65px;
    /* 125% */
    letter-spacing: -0.52px;
    max-width: 830px;
    margin: 0 auto 17px;
    @include mobile() {
      padding: 0 25px;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 65px; /* 162.5% */
      letter-spacing: -0.4px;
      margin: 0 auto 10px;
    }
  }

  p {
    color: #000;
    text-align: center;
    font-family: var(--secondary-font);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 150% */
    letter-spacing: -0.2px;
    max-width: 670px;
    margin: 0 auto 30px;
    @include mobile() {
      padding: 0 25px;
    }
  }
}

.catContainer {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.scroll {
  color: #000;
  text-align: center;
  font-family: var(--secondary-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: -0.12px;
  margin-top: 22px;
  @include mobile() {
    display: none;
  }
}

.scrollImage {
  margin: 33px auto 0;
  display: block;
  @include mobile() {
    display: none;
  }
}

.booksCollection {
  @include mobile() {
    margin-top: 20px;
    display: flex;
    gap: 20px;
  }
  img {
    width: 200px;
    height: 267.63px;
    object-fit: contain;
    z-index: 1;
    @include mobile() {
      display: none;
      position: relative;
      width: 80px;
      height: 107.052px;
      &:nth-child(odd) {
        transform: translateY(40px);
      }
    }
  }

  .book1 {
    position: absolute;
    top: 0;
    left: -120px;
    @include mobile() {
      top: unset;
      left: unset;
    }
  }

  .book2 {
    position: absolute;
    top: 40%;
    left: -70px;
  }

  .bookLeftOne {
    position: absolute;
    top: 35%;
    left: -500px;
    z-index: 2;
    @include mobile() {
      top: unset;
      left: unset;
    }
  }

  .bookLeftTwo {
    position: absolute;
    top: 74%;
    left: -340px;
    z-index: 2;

    .topPadding {
      position: absolute;
      top: 30px;
    }
  }

  .book4 {
    position: absolute;
    top: -100px;
    right: -130px;
    z-index: 2;
    @include mobile() {
      top: unset;
      right: unset;
    }
  }

  .book5 {
    position: absolute;
    top: 30%;
    right: -80px;
    @include mobile() {
      top: unset;
      right: unset;
    }
  }

  .bookRightOne {
    position: absolute;
    top: 35%;
    right: -500px;
    z-index: 2;
  }

  .bookRightTwo {
    position: absolute;
    top: 70%;
    right: -130px;
    z-index: 2;

    .topRightPadding {
      position: absolute;
      top: -30px;
    }
    @include mobile() {
      top: unset;
      right: unset;
    }
  }
}

.heroBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
}

.wrapperOne {
  display: flex;
  // align-items: center;
  flex-direction: column;
  // justify-content: center;
  //padding: 120px;
  z-index: 1;
  // margin-top: -1000px;
  @include mobile() {
    padding: 25px;
  }
  h2 {
    width: 100%;
    color: #000;
    text-align: center;
    font-family: var(--primary-font);
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 65px; /* 135.417% */
    letter-spacing: -0.48px;
    margin-bottom: 40px;
    display: none;
    @include mobile() {
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.32px;
      display: block;
    }
  }
}
.list {
  display: flex;
  gap: 30px;
  @include mobile() {
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    transform: none !important;
  }
  .card {
    border-radius: 19.8px;
    background: #f4f6ff;
    padding: 50px 50px 0 50px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    @include mobile() {
      padding: 15px;
      margin-bottom: 50px;
    }

    h3 {
      color: #b50d0d;
      text-align: center;
      font-size: 32px;
      font-family: var(--primary-font);
      font-style: normal;
      font-weight: 300;
      line-height: 65px; /* 203.125% */
      letter-spacing: -0.32px;
      margin-bottom: 25px;
      order: 1;
      @include mobile() {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.18px;
        order: 2;
        position: absolute;
        bottom: -60px;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
      }
    }

    &.two {
      h3 {
        color: #00823d;
      }
    }
    &.three {
      h3 {
        color: #d47844;
      }
    }
    img {
      //transform: translateY(100px);
      margin-bottom: -60px;
      margin-left: 20px;
      order: 2;
      @include mobile() {
        margin-bottom: 0;
        width: 110px;
        height: 147.54px;
        order: 1;
      }
    }
  }
}
