@import '../../Styles/mixins';
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  font-family: 'Satoshi', sans-serif;
  font-weight: 400;
  z-index: 99;
  &.transparent{
    background: transparent;

  }
  &.solid{
    background: #fff;
  }
}
.header {
  display: flex;
  padding: 15px 0;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 99;
  @include mobile() {
    padding: 10px 25px;
    background: #fff;
  }
}
.logo {
  width: 150px;
  height: 65px;
  object-fit: contain;
  @include mobile() {
    width: 100px;
    height: 43px;
  }
}
.menuList {
  display: flex;
  gap: 66px;
  list-style: none;
  @include mobile() {
    transform: translateX(-100%);
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    flex-direction: column;
    padding: 25px;
    z-index: 66;
    transition: all 0.3s ease-in-out;
    gap: 20px;
    li {
      color: #000;
      font-family: var(--secondary-font);
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 65px; /* 270.833% */
      letter-spacing: -0.24px;
    }
  }
  &.open {
    transform: translateX(0);
  }
  .letsTalk {
    display: none;
    @include mobile() {
      display: block;
      width: 100%;
    }
    .cta {
      width: 100%;
    }
  }
}
.contactDesktop {
  display: block;
  @include mobile() {
    display: none;
  }
}
.menuIcon {
  width: 30px;
  height: 30px;
  object-fit: contain;
  cursor: pointer;
  display: none;
  @include mobile() {
    display: block;
  }
}
