@import '../../../Styles/mixins.scss';
.mainCont {
  background: #f4f6ff;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
padding: 50px 0;
  h4 {
    color: #000;
    text-align: center;
    font-family: var(--primary-font);
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 64.742px;
    /* 134.878% */
    letter-spacing: -0.48px;
    max-width: 400px;
    margin: 0 auto 20px;
    @include mobile() {
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.32px;
      padding: 0 25px;
    }
  }

  p {
    max-width: 600px;
    margin: 0 auto 25px;
    color: #000;
    text-align: center;
    font-family: var(--secondary-font);
    font-size: 19.92px;
    font-style: normal;
    font-weight: 400;
    line-height: 29.881px;
    /* 150% */
    letter-spacing: -0.199px;
    @include mobile() {
      padding: 0 25px;
    }
  }
}

.ctaContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.countWrapper {
  margin-top: 60px;
  @include mobile() {
    padding: 0 25px;
  }

  .countLabel {
    color: #000;
    text-align: center;
    font-family: var(--primary-font);
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.2px;
  }

  .countList {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 20px;
    @include mobile() {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 20px;
    }

    .countContainer {
      padding: 0 20px;
      border-right: 1px solid #000;
      &:last-child {
        border-right: none;
      }
      @include mobile() {
        &:nth-child(2) {
          border-right: none;
        }
      }
      .number {
        color: #7689e1;
        text-align: center;
        font-family: var(--secondary-font);
        font-size: 52.165px;
        font-style: normal;
        font-weight: 400;
        line-height: 69.347px;
        /* 132.937% */
        letter-spacing: -0.522px;
        margin-bottom: 5px;
        @include mobile() {
          font-size: 41.598px;
          font-style: normal;
          font-weight: 400;
          line-height: 55.299px; /* 132.937% */
          letter-spacing: -0.416px;
        }
      }

      .caption {
        color: #000;
        text-align: center;
        font-family: var(--primary-font);
        font-size: 16.278px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.163px;
      }
    }

    .verticalDivider {
      width: 0.9px;
      height: 78px;
      background: #000;
    }
  }
}

.bookList {
  @include mobile() {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 30px;

    img {
      &:nth-child(even) {
        transform: translateY(20px);
      }
    }
  }
}

.book1 {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 160.877px;
  height: 216px;

  @include mobile() {
    position: relative;
    top: unset;
    left: unset;
    width: 120.877px;
    height: 176px;
  }
}

.book2 {
  position: absolute;
  top: 10%;
  right: 9%;
  width: 200px;
  height: 268.646px;
  @include mobile() {
    position: relative;
    top: unset;
    right: unset;
    width: 120.877px;
    height: 176px;
  }
}

.book3 {
  position: absolute;
  top: 60%;
  right: -2%;
  width: 173px;
  height: 261px;
  @include mobile() {
    position: relative;
    top: unset;
    right: unset;
    width: 120.877px;
    height: 176px;
  }
}

.book4 {
  position: absolute;
  bottom: 0;
  left: -2%;
  @include mobile() {
    position: relative;
    top: unset;
    left: unset;
    width: 120.877px;
    height: 176px;
  }
}
