@import '../../../Styles/mixins.scss';
.wrapper {
  padding: 100px 0 0 0;
  @include mobile() {
    padding: 100px 0 0 0;
  }
}
.container {
  display: flex;
  align-items: center;
  @include mobile() {
    flex-direction: column;
    gap: 40px;
  }
}
.formWrapper {
  flex: 2;
  display: flex;
  justify-content: center;
  padding: 10px 80px;
  @include mobile() {
    padding: 0 25px;
  }
  .internal {
    max-width: 600px;
  }
  h2 {
    color: #000;
    text-align: left;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.42px;
    margin-bottom: 50px;
    @include mobile() {
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.32px;
      margin-bottom: 20px;
    }
  }
  p {
    color: #000;
    font-family: var(--secondary-font);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
    margin-bottom: 50px;
    @include mobile() {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.18px;
      margin-bottom: 20px;
    }
  }
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    row-gap: 30px;
    @include mobile() {
      grid-template-columns: 1fr;
      row-gap: 20px;
    }
  }
  .formGroup {
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #ff7272;
    background: #fff;
    @include mobile() {
      padding: 10px;
    }
  }
  input,
  select {
    border: none;
    width: 100%;

    color: rgba(255, 138, 138, 1);
    font-family: var(--secondary-font);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.18px;
    background: transparent;
    &::placeholder {
      color: rgba(255, 138, 138, 0.5);
    }
    &:focus {
      outline: none;
    }
    @include mobile() {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;
    }
  }
  span {
    font-family: var(--secondary-font);
    font-size: 14px;
    color: red;
  }
  select {
    width: 100%;
  }
  .addtionalformGroup {
    margin-top: 30px;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #ff7272;
    background: #fff;
    @include mobile() {
      padding: 10px;
      margin-top: 20px;
    }
  }
  button {
    border-radius: 10px;
    background: #ff7272;
    width: 100%;
    border: none;
    padding: 15px;
    margin-top: 30px;
    color: #fff;
    font-family: var(--secondary-font);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
    &.submitting {
      cursor: not-allowed;
      display: flex;
        align-items: center;
      justify-content: center;
      gap: 20px;
      .loader {
        width: 20px;
        --b: 8px;
        aspect-ratio: 1;
        border-radius: 50%;
        padding: 1px;
        background: conic-gradient(#0000 10%, #fff) content-box;
        -webkit-mask: repeating-conic-gradient(
            #0000 0deg,
            #000 1deg 20deg,
            #0000 21deg 36deg
          ),
          radial-gradient(
            farthest-side,
            #0000 calc(100% - var(--b) - 1px),
            #000 calc(100% - var(--b))
          );
        -webkit-mask-composite: destination-in;
        mask-composite: intersect;
        animation: l4 1s infinite steps(10);
      }
      @keyframes l4 {
        to {
          transform: rotate(1turn);
        }
      }
    }
    &.submitted {
      display: flex;
      justify-content: center;
        align-items: center;
      gap: 10px;
      background: #00D615;
      cursor: not-allowed;
    }
  }
}

.details {
  //padding: 90px 130px;
  //display: flex;
  //flex-direction: column;
  //gap: 30px;
  //background: #f4f6ff;
  flex: 1;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  @include mobile() {
    padding: 50px 25px;
  }
  h4 {
    color: #000;
    text-align: left;

    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;
    margin-bottom: 20px;
    @include mobile() {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.24px;
    }
  }
  a,
  p {
    color: #000;
    text-align: left;
    font-family: var(--secondary-font);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.18px;
  }
  a {
    white-space: nowrap;
  }

  .socialList {
    display: flex;
    gap: 16px;
    a {
      height: 40px;
      width: 40px;
      background: #7689e1;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.thankYou {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: 600;
  color: #000;
  font-family: var(--primary-font);
  text-align: center;
  @include mobile() {
    font-size: 32px;
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
  }
}
