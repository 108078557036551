@import '../../../Styles/mixins.scss';
.wrapper {
  padding: 40px 0;
  max-width: 1000px;
  margin: 0 auto;
  @include mobile() {
    padding: 20px 25px;
  }
}
.card {
  border-radius: 20px;
  background: #f4f6ff;
  display: flex;
  gap: 80px;
  justify-content: space-between;
  @include mobile() {
    flex-direction: column;
    gap: 5px;
  }
}
.left {
  padding: 20px;
  .title {
    color: #141414;
    font-family: var(--primary-font);
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.2px;
    @include mobile() {
      text-align: center;
    }
  }
  button{
    margin-top: 10px;
    border-radius: 10px;
    background: #8095F5;
    border: none;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 100px;
    color: #FFF;
    font-family: var(--secondary-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.16px;
  }
}
.right {
  overflow: hidden;
  position: relative;
  flex: 1;
  @include mobile() {
   flex: unset;
    height: 100px;
  }
  img {
    width: 140px;
    height: auto;
    object-fit: contain;
  }
  img:nth-child(1) {
    position: absolute;
    top: 0;
    right: 450px;
  }
  img:nth-child(2) {
    position: absolute;
    bottom: 0px;
    right: 350px;
  }
  img:nth-child(3) {
    position: absolute;
    top: -10px;
    right: 220px;
  }
  img:nth-child(4) {
    position: absolute;
    top: 0;
    right: 100px
  }
  img:nth-child(5) {
    position: absolute;
    bottom: 0px;
    right: 140px;
  }
  img:nth-child(6) {
    position: absolute;
    top: 10px;
    right: 0px;
  }
}
