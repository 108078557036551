@import '../../../Styles/mixins.scss';

.wrapper {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f4f6ff;
  position: relative;
  @include mobile() {
    padding: 40px 25px;
  }
}
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  @include mobile() {
    flex-direction: column;
    gap: 50px;
  }
}
.content {
  flex: 1;
  @include mobile() {
    text-align: center;
  }
  h5 {
    color: #000;
    font-family: var(--primary-font);
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 134.667%; /* 43.093px */
    letter-spacing: -0.32px;
    margin-bottom: 20px;
    @include mobile() {
      color: #000;
      font-size: 24px;
      text-align: center;
      font-style: normal;
      font-weight: 400;
      line-height: 134.667%; /* 32.32px */
      letter-spacing: -0.24px;
    }
  }
  p {
    color: #000;
    font-family: var(--secondary-font);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
    letter-spacing: -0.18px;
    margin-bottom: 20px;
    @include mobile() {
      color: #000;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 166.667% */
      letter-spacing: -0.18px;
    }
  }
  .reviewList {
    margin-top: 20px;
    display: flex;
    gap: 18px;

    max-width: 500px;
    @include mobile() {
      flex-direction: column;
    }
    .card {
      border-radius: 9.165px;
      border: 0.917px solid #ced6ff;
      background: #fff;
      padding: 10px;
      display: flex;
      gap: 14px;

      .text {
        color: #000;
        font-family: var(--secondary-font);
        font-size: 14.471px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        white-space: nowrap;
        .rating {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
  }
}
.sliderContainer {
  flex: 1;
}
