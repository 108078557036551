@import '../../../Styles/mixins.scss';
.wrapper {
  padding: 90px 0;
  @include mobile() {
    padding: 40px 25px;
  }
}
.row {
  display: flex;
  gap: 120px;
  justify-content: space-between;
  @include mobile() {
    flex-direction: column;
    gap: 50px;
  }
}
.content {
  flex: 1;
  h5 {
    color: #000;
    font-family: var(--primary-font);
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;
    margin-bottom: 17px;
  }
  p {
    color: #000;
    font-family: var(--secondary-font);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.2px;
    margin-bottom: 36px;
  }
  ul {
    list-style: none;
    display: flex;
    gap: 15px;
    flex-direction: column;
    li {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #616161;
      font-family: var(--secondary-font);
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.2px;
      .divider {
        background: #616161;
        height: 1px;
        width: 300px;
      }
    }
  }
}
.formWrapper {
  flex: 1;
  max-width: 420px;
  display: flex;
  gap: 30px;
  flex-direction: column;

  .formGroup {
    border-radius: 10px;
    border: 1px solid #7289ff;
    background: #fff;
    padding: 15px;
    input,
    .select {
      background: transparent;
      border: none;
      width: 100%;
      color: #000;
      font-family: var(--secondary-font);
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.24px;
      opacity: 0.8;
      &::placeholder {
        opacity: 0.6;
      }
      &:focus-within {
        outline: none;
      }
    }
  }
  span {
    font-family: var(--secondary-font);
    font-size: 14px;
    color: red;
  }
  .cta {
    border-radius: 10px;
    background: #7289ff;
    padding: 15px;
    text-align: center;
    color: #fff;
    font-family: var(--secondary-font);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
    border: none;
    &.submitting {
      cursor: not-allowed;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      .loader {
        width: 20px;
        --b: 8px;
        aspect-ratio: 1;
        border-radius: 50%;
        padding: 1px;
        background: conic-gradient(#0000 10%, #fff) content-box;
        -webkit-mask: repeating-conic-gradient(
            #0000 0deg,
            #000 1deg 20deg,
            #0000 21deg 36deg
          ),
          radial-gradient(
            farthest-side,
            #0000 calc(100% - var(--b) - 1px),
            #000 calc(100% - var(--b))
          );
        -webkit-mask-composite: destination-in;
        mask-composite: intersect;
        animation: l4 1s infinite steps(10);
      }
      @keyframes l4 {
        to {
          transform: rotate(1turn);
        }
      }
    }
    &.submitted {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: #00d615;
      cursor: not-allowed;
    }
  }
}

