@import '../../../Styles/mixins.scss';
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0 120px;
  margin-top: -300px;
  @include mobile(){
    padding: 0 25px;
    margin-top: 40px;
  }
}
.title {
  color: #ff3e4f;
  text-align: center;
  font-family: var(--secondary-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;
  margin-bottom: 10px;
}
.subTitle {
  color: #000;
  text-align: center;
  font-family: var(--primary-font);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 43.556px; /* 136.111% */
  letter-spacing: -0.32px;
  max-width: 595px;
  margin: 0 auto;
  @include mobile() {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 43.556px; /* 181.481% */
    letter-spacing: -0.24px;
  }
}
.tabWrapper {
  margin-top: 30px;
  .tabList {
    display: flex;
    justify-content: center;
    align-items: center;
    //gap: 10px;
    .tabItem {
      color: #000;
      text-align: center;
      font-family: var(--secondary-font);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.18px;
      border-radius: 32.869px;
      padding: 5px 20px;
      cursor: pointer;
      position: relative;
      z-index: 33;
      &.active {
        background: #ff3e4f;
        color: #ffffff;
        transition: background-color 0.5s ease;
      }
    }
  }
  .tabContent {
    margin-top: 50px;
    text-align: center;
    .contentImage {
      max-width: 800px;
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
}
