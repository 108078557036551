@import '../../../Styles/mixins.scss';
.pageButtonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  .buttonLayout {
    border-radius: 42px;
    border: 1px solid #000;
    padding: 8px 5px;
    .list {
      padding: 5px 20px;
      color: #232323;
      text-align: center;
      font-family: var(--secondary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;
      @include mobile() {
        padding: 5px 15px;
      }
      &.active {
        background: #a8b8ff;
        border-radius: 42px;
      }
    }
  }
}
