@import '../../../Styles/mixins.scss';
.sliderContainer {
  flex: 1;
  max-width: 400px;
  position: relative;
  min-height: 492px;
  @include mobile() {
    max-width: 300px;
    width: 300px;
    min-height: 328px;
  }
}
.item {
  position: absolute;
  top: 0;
  left: 0;
  transition:
    transform 0.3s ease-in-out,
    z-index 0s linear 0.3s;
  background: #fff;
  width: 401.663px;
  height: 492.583px;
  border-radius: 8.056px;
  overflow: hidden;

  @include mobile() {
    max-width: 300px;
    width: 267.776px;
    height: 328.389px;
  }
  .quoteIcon {
    width: 218px;
    height: 168px;
    object-fit: contain;
    top: -20px;
    right: -20px;
    position: absolute;
  }
  .content {
    color: #000;
    font-size: 26px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.283px;
    padding: 35px 32px;
    position: relative;
    @include mobile() {
      font-size: 18.861px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.189px;
      padding: 40px 20px;
    }
  }

}
.navigation {
  position: absolute;
  bottom: 20px;
  z-index: 99;
  right: 20px;
  display: flex;
  gap: 15px;
  align-items: center;
  @include mobile(){
    right: 40px;
    gap: 10px;
  }
  .dots {
    display: flex;
    gap: 5px;
    align-items: center;
    .dot {
      width: 7.073px;
      height: 7.073px;
      background: #f0f3ff;
      border-radius: 100%;
      @include mobile(){
        width: 5px;
        height: 5px;
      }
      &.active {
        background: #ced6ff;
      }
    }
  }
  button {
    background: none;
    border: none;
    cursor: pointer;
    img {
      width: 22px;
      height: 22px;
      @include mobile(){
        width: 14px;
        height: 14px;
      }
    }
  }
}
